import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Client } from '../../Client'
import ProductPage from './ProductPage'
import LoadingPage from '../../LoadingPage'
import useLocalStorage from '../../UseLocalStorage'

const ProductList = () => {
        const search = useLocation().search.split('=')[1]
        const [products, setProducts] = useLocalStorage('products')
        const [filteredList, setFilteredList] = useState([]);
        
        
        const [loading, setLoading] = useState(true);
        const prodCopy = [...products]
        
        useEffect(() => {
                if(search === 'all'){
                        fetchAll()
                }else {
                        filter()
                }
        }, [search]);
        
        const fetchAll = () => {
                setLoading(true)
                setFilteredList(prodCopy)
                setLoading(false)

        }

        const filter = () => {
                setLoading(true)
                const flist = prodCopy.filter((product) => product?.fields?.category == search || product?.fields?.producer == search )
                setFilteredList(flist)
                setLoading(false)
        }


  return (
        <>
                {
                        loading
                        ?
                        <LoadingPage />
                        :
                        <ProductPage products={filteredList} />

                }
        </>
  )
}

export default ProductList
