import React from 'react'
import './Footer.css'
import { IoCarOutline, IoCardOutline, IoCashOutline, IoPersonOutline, IoShieldCheckmarkOutline } from 'react-icons/io5'
import { BsAndroid2, BsApple, BsFacebook, BsInstagram, BsMessenger, BsTwitter, BsWhatsapp } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className='Footer'>
      <section className='footer_upper'>
        <div className='container footer_top_grid'>


          <div className='footer_grid_item_value'>
            <div className='icon'> <IoShieldCheckmarkOutline /> </div>
            <div className='desc'>
              <div className='title'>Great value</div>
              <div className='text'>We offer competitive prices on over 100 million items.</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoCarOutline /> </div>
            <div className='desc'>
              <div className='title'>worldwide shopping</div>
              <div className='text'>We ship to over 200 countries and regions, and our site comes in 12 languages.</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoCardOutline /> </div>
            <div className='desc'>
              <div className='title'>safe payment</div>
              <div className='text'>Pay with the world’s most popular and secure payment methods.</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoShieldCheckmarkOutline /> </div>
            <div className='desc'>
              <div className='title'>shop with confidence</div>
              <div className='text'>Our Buyer Protection policy covers your entire purchase journey.</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'> <IoPersonOutline /> </div>
            <div className='desc'>
              <div className='title'>help center</div>
              <div className='text'>Round-the-clock assistance for a smooth shopping experience.</div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
            <div className='icon'>
              <span className='icon'><BsAndroid2 />  </span>
              <span className='icon'><BsApple /> </span>
            </div>
            <div className='desc'>
              <div className='title'>Shop better</div>
              <div className='text'>Download the app for mobile-only features such as image search and discount games.</div>
            </div>
          </div>

        </div>
        <div className='container footer_top_grid footer_bottom_grid'>
          <div className='footer_grid_item_value'>
              <div className='title'>stay connected</div>
            <div className='icon'>
              <span className='icon'> <BsFacebook /> </span>
              <span className='icon'> <BsTwitter /> </span>
              <span className='icon'> <BsInstagram /> </span>
              <span className='icon'> <BsMessenger /> </span>
              <span className='icon'> <BsWhatsapp /> </span>
            </div>
          </div>

          <div className='footer_grid_item_value'>
          </div>

          <div className='footer_grid_item_value'>
              <div className='title'>shopping with us</div>
            <div className='desc'>
              <div className='text'>
                <p><Link to='/cart/checkout'>Making payments</Link></p>
                <p><Link to='/delivery-options'>delivery options</Link></p>
                <p><Link to='/buyer-protection'>Buyer protection</Link></p>
                
              </div>
            </div>
          </div>

          <div className='footer_grid_item_value'>
              <div className='title'>customer service</div>
            <div className='desc'>
              <div className='text'>
                <p><Link to='/cart/checkout'>help center</Link></p>
                <p><Link to='/delivery-options'>Transaction Services Agreement for non-EU/UK Consumers</Link></p>
                <p><Link to='/buyer-protection'>terms and conditions for EU/EEA/UK customers (transactions)</Link></p>
                <p><Link to='/buyer-protection'>take our feedback survey</Link></p>
                
              </div>
            </div>
          </div>


          <div className='footer_grid_item_value'>
              <div className='title'>colaborate with us</div>
            <div className='desc'>
              <div className='text'>
                <p><Link to='/cart/checkout'>Partnerships</Link></p>
                <p><Link to='/delivery-options'>affiliate program</Link></p>
                <p><Link to='/buyer-protection'>DS Center</Link></p>                
              </div>
            </div>
          </div>

        </div>
      </section>

      <section>
        <div className='container footer_lower_grid'>
          <div className='buttom_desc'>
            <div className='indi'>
              <div className='title'>Help</div>
              <div className='desc'>Help Center, Disputes & Reports, Buyer Protection, Report IPR infringement, Regulated Information</div>
            </div>
            <div className='indi'>
              <div className='title'>browse by category</div>
              <div className='desc'>All Popular, Product, Promotion, Low Price, Great Value, Reviews, Blog, Seller Portal, BLACK FRIDAY, LLC Parts Assistant</div>
            </div>
            
          </div>
          <div className='buttom_desc'>
            <div className='indi'>
              <div className='title'>LLC Parts multi-Language sites</div>
              <div className='desc'>Russian, Portuguese, Spanish, French, German, Italian, Dutch, Turkish, Japanese, Korean, Thai, Vietnamese, Arabic, Hebrew, Polish</div>
            </div>
            <div className='indi'>
              <div className='title'>alibaba group</div>
              <div className='desc'>Alibaba Group Website, LLC Parts, Alimama, Alipay, Fliggy, Alibaba Cloud, Alibaba International, AliTelecom, DingTalk, Juhuasuan, Taobao Marketplace, Tmall, Taobao Global, AliOS, 1688</div>
            </div>

          </div>

        </div>
      </section>
      <section className='footer_under'>
        <div className='container'>
          <p>Intellectual Property Protection - Privacy Policy - Sitemap - Terms of Use - Information for EU consumers - Transaction Services Agreement for non-EU/UK Consumers - Terms and Conditions for EU/EEA/UK Consumers (Transactions) - User Information Legal Enquiry Guide ©️2010-2022 LLC Parts.com. All rights reserved.  增值电信业务经营许可证 浙B2-20120091-8  浙公网安备 33010802002248号</p>
        </div>
      </section>
    </div>
  )
}

export default Footer
