import React, { useEffect, useState } from 'react'


import Off from '../Addons/Off'
import Stars from '../Addons/Stars'
import { BsChevronDown, BsShield, BsShieldCheck, BsFacebook, BsInstagram, BsMessenger, BsTwitter, BsWhatsapp } from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'
import { Link } from 'react-router-dom'
import ProductPage from './ProductPage'
import useLocalStorage from '../../UseLocalStorage'


const Product = ({ product, country, handleAddToCart }) => {
        const stars = Array(5).fill(0)
        const img = Array(6).fill(0)
        const [recommendationProduct, setRecommendationProduct] = useState([]);
        const [products, setProducts] = useLocalStorage('products');

        useEffect(() => {
                const rec = products.filter(pItem => pItem?.fields.category === product?.fields?.category)
                setRecommendationProduct( rec.splice(0, 4) )
                console.log(recommendationProduct);
          
        }, []);
        
        const openWhatsapp = () => {

        }
        

  return (
    <div className='product_details_page'>
        <div className='product_detail_top_grid'>
                <div className='images'>
                        <div className='imgMain'>
                                {product?.fields?.images && <img src={product?.fields?.images[0]?.fields?.file?.url} alt={product?.fields?.name} /> }
                        </div>
                        <div className='subImages'>
                        </div>
                </div>
                <div className='content'>
                        <div className='description'><h2>{ product?.fields?.name }</h2></div>
                        <div className='ratings' draggable={false}>
                                <Stars value={5} />
                                <span className='rating_value'>4.5 </span>
                                <span className='iconB'><BsChevronDown /></span>
                        </div>
                        <div className='price_dis'>
                                <p>
                                        <span className='price'>USD {product?.fields?.price- (product?.fields?.price *product?.fields?.discount /100)}</span>
                                        {
                                                product?.fields?.discount > 0 && <span className='price priceOld'>USD {product?.fields?.price}</span>
                                        }
                                        
                                </p>
                                {
                                        product?.fields?.discount > 0 && <p><Off discount={ product?.fields?.discount } /> </p>
                                }
                                
                        </div>
                        <div className='shipping_address'>
                                <span>Ships to</span>
                                <span className='iconB'> <IoLocationOutline /> </span>
                                <span className='location_country'>{ country }</span>
                        </div>
                        <div className='shipping_price'>
                                <span>Shipping: </span>
                                <span className='shipping_cost'>USD 25</span>
                                <p>From Japan to { country } via Cainiao Super Economy for Special Goods Estimated delivery: 58-58 days</p>
                        </div>
                        <div className='buttons'>
                                <a href={`https://wa.me/+13395261104?text=Hello there, I will like to buy this product \n ${ product?.fields?.name }`} target='_blank' rel='noreferrer' className='buyBtn'>Buy now</a>
                                <button onClick={(e) => {handleAddToCart(product, e)}} className='buyBtn addCartbtn'>add to cart</button>
                        </div>
                        <div className='shipping_talk'>
                                <div className='iconB2'>
                                        <BsShieldCheck />
                                </div>
                                <div className='shipping_desc'>
                                        <p>75-Day Buyer Protection</p>
                                        <span>Money back guarantee</span>
                                </div>
                        </div>
                        <div className='share_product_links'>
                                <div className='text'>share with friends</div>
                                <div className='icon'>
                                        <span className='icon'> <a href="http://www.facebook.com/"> <BsFacebook /> </a></span>
                                        <span className='icon'> <a href="http://www.twitter.com/"><BsTwitter /> </a></span>
                                        <span className='icon'> <a href="http://www.instagram.com/"><BsInstagram /> </a></span>
                                        <span className='icon'> <a href="http://www.messager.com/"><BsMessenger /> </a></span>
                                        <span className='icon'> <a href="http://www.wa.me/?message='I will like to share with you this incredible item '/"><BsWhatsapp /> </a></span>
                                </div>
                        </div>

                </div>
                <div className='side_comments'>

                </div>
        </div>
        <div className='recommendation_and_conclusion'>
                <div className='pined_category'>
                        <div className='title'>Store category</div>
                        <ul className='category_list'>
                                <li className='category_item'>
                                        <Link to='/product-list?id=engine'>Engine</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=fuel'>Fuel system & Lubricant</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=exaust'>Body & Exaust</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=transmission'>Transmission</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=cooling'>Cooling & Heating</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=wheel'>Wheel & Spring</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=light'>Light & Electrics</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=steering'>steering</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=wheel'>wheels and tires</Link>
                                </li>
                                <li className='category_item'>
                                        <Link to='/product-list?id=uncategorized'>uncategorized</Link>
                                </li>
                        </ul>
                </div>
                <div>

                        <div className='productGridItem'>
                                <div className='detail-review'>
                                        <a href='http://www.facebook.com/' className='productReviewLink'>Product Details</a>
                                        <a href='#product-review' className='productReviewLink'>Product Review(2)</a>
                                </div>
                                <section className="itemDescription" id='product-details'>
                                        <h4 className="title">Product Details</h4>
                                        <p>{ product?.fields?.description }</p>
                                        <ul className='productDetailList'>
                                                <li className='productDetailListItem'>
                                                        <p className='key'>Produced In</p>
                                                        <p className='value'>{ product?.fields?.producedIn }</p>
                                                </li>
                                                <li className='productDetailListItem'>
                                                        <p className='key'>Brand</p>
                                                        <p className='value'>{ product?.fields?.producer }</p>
                                                </li>
                                                <li className='productDetailListItem'>
                                                        <p className='key'>Producer</p>
                                                        <p className='value'>{ product?.fields?.producer }</p>
                                                </li>
                                                <li className='productDetailListItem'>
                                                        <p className='key'>Model</p>
                                                        <p className='value'>
                                                        {
                                                                product?.fields?.model?.map((model, index) => (
                                                                        <span key={index}>{model}</span>
                                                                ))
                                                        }
                                                        </p>
                                                </li>
                                                <li className='productDetailListItem'>
                                                        <p className='key'>Type</p>
                                                        <p className='value'>{ product?.fields?.category }</p>
                                                </li>
                                        </ul>
                                </section>

                                {/* ============ REVIEWS SECTION========== */}
                                <section className="itemDescription reviews" id='product-review'>
                                <h4 className="title">Reviews</h4>
                                
                                {
                                        product?.fields?.reviews?.map(rev => (
                                        <div key={rev} className='review'>
                                                <div className='profile'>
                                                        <div className='profilePic'>
                                                        </div>
                                                </div>
                                                <div className='review-content'>
                                                        <span>{ rev?.fields?.name }</span>
                                                        <Stars value={ rev?.fields?.rating } />
                                                        <p>{ rev?.fields?.review }</p>
                                                </div>
                                        </div>
                                        ))
                                }
                                
                                </section>

                                {/* ============ RECOMMENDATION ============ */}
                        </div>
                        <section className='recommendation'>
                                <h4 className="title">recommendation</h4> 
                                <span>(People who buy this also seach for ) </span>
                                <div className='recommendation_items'>
                                        <ProductPage products={recommendationProduct} />
                                </div>
                        </section>
                </div>
        </div>

    </div>
  )
}

export default Product
