import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsArrowLeft, BsShieldCheck } from 'react-icons/bs'

import './Cart.css'
import CartItem from './CartItem'
import { CartContext } from '../../Context/CartContext'

const Cart = ({ products }) => {
        
        const [cart, setCart] = useContext(CartContext);
        
        const removeProduct = (id) => {
                function filteredCart(){
                        return cart.filter(cartI=>{
                                let pid = cartI.product.sys.id;
                                if(pid !== id){
                                        return cartI;
                                }
                                return null;
                
                        })
                }
                
                setCart(filteredCart())
        }
        
        // let totalPrice = 0;
        // cart.forEach(elt => {
        //         totalPrice += elt.product.fields.price
        // }

        
        const recommendation = () => {
                console.log("product all ", products);
        }

        let totalPrice = 0;

        cart.forEach(elt => {
            totalPrice += elt.product?.price
        });
        let totalWithShipping = totalPrice + cart.length > 0  ? 25 : 0
        
  return (
        <>
    <div className='cart'>
      <div className='cart_list_section'>
                <div className='cartSection'>
                        <div className='title'>Shopping Cart (1)</div>
                        <div className='desc'>
                                <span className='icon'><input type='radio' /></span>
                                <span>Select all items</span>
                        </div>
                </div>
                <div className='cartItemsSection'>
                        <div className='desc lineCart'>
                                <span className='icon'><input type='radio' /></span>
                                <span className='title'>Outdoor Sport Dropship Store</span>
                        </div>
                        {
                                cart.map(cartItem => (
                                        <CartItem key={ cartItem?.sys?.id } product={ cartItem } handleRemove ={removeProduct}/>
                                ))
                        }
                </div>
                <Link className='cart_back_home' to= '/'><span className="icon"><BsArrowLeft /></span>Return to Shopping </Link>
      </div>
      <div className='cart_summary'>
        <div className='summary'>
                <div className='title'>Summary</div>
                <div className='summary_det'>
                        <p>subtotal</p>
                        <p>USD {totalPrice.toFixed(2)}</p>
                </div>
                <div className='summary_det'>
                        <p>shipping fee</p>
                        <p>USD {cart.length > 0  ? 25 : 0.00}</p>
                </div>
                <div className='summary_det'>
                        <p>total</p>
                        <p>US {totalWithShipping.toFixed(2)}</p>
                </div>
                <a href={`https://wa.me/+13395261104?text=Hello there, I wish to buy some products from your website. Can you tell me more about them?`} target='_blank' rel='noreferrer' className='checkout_button'>checkout ({cart.length})</a>
        </div>
        <div className='summary payment_methods'>
                <div className='title'>Payment Methods</div>
                <div className='payIcons'>
                        <span className='icon'></span>
                        <span className='icon'></span>
                        <span className='icon'></span>
                </div>
                <div className='title'>Buyer Protection</div>
                <p>
                        <span><BsShieldCheck /> </span>
                        <span className='protect'>Get full refund if the item is not as described or if is not delivered</span>
                </p>
        </div>
      </div>
    </div>

      </>
  )
}

export default Cart
