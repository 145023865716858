import React, { useState } from 'react'
import './Auth.css'
import Register from './Register'
import Signin from './Signin';
import { VscChromeClose } from 'react-icons/vsc';

const LoginModal = ({ handleShowLoginModal }) => {
  const [register, setRegister] = useState(true);
  
  return (
    <div>
      <div className='modal'>
            <div onClick={() => handleShowLoginModal(false)} className='shadowLogin' />
            <div className='loginModal'>
                <div className='loginModal_head'>
                        <div onClick={() => setRegister(true)} className={`title ${register ? 'active': ''}`}>Register</div>
                        <div onClick={() => setRegister(false)} className={`title ${!register ? 'active': ''}`}>login</div>
                        <div onClick={() => handleShowLoginModal(false)} className='closeModal'><VscChromeClose /></div>
                </div>
                <div className='auth'>
                  {
                    register ? <Register /> : <Signin />
                  }
                  
                </div>
            </div>
          </div>
    </div>
  )
}

export default LoginModal
