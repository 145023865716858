import React from 'react'
import {ReactComponent as Loader } from './Assets/loading.svg'

const LoadingPage = () => {
  return (
    <div className='loadingPage'>
      <div className='loadingInside'>
        <h1>Loading ...</h1>
        <Loader />
      </div>
    </div>
  )
}

export default LoadingPage
