import React from 'react'
import AdvertOne from './Assets/img/advert_one.webp'

const Discount = () => {
  return (
    <div className='discover_top_pin'>
        <div className='discover_img'>
                <img  src={AdvertOne} alt="family day" />
        </div>
    </div>
  )
}

export default Discount
