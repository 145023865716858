import React, { useContext, useLayoutEffect, useRef, useState } from 'react'
import './Product.css'
import './ProductDetails.css'

import { Link, useLocation } from 'react-router-dom'
import { CartContext } from '../../Context/CartContext'
import { Client } from '../../Client'
import LoadingPage from '../../LoadingPage'
import Product from './Product'
import { FaTimes } from "react-icons/fa";


const ProductDetails = () => {
        const [cart, setCart] = useContext(CartContext);
        const [loading, setLoading] = useState(false);
        const [country, setCountry] = useState('United States');
        
        const [product, setProduct] = useState({});

        const location = useLocation()
        const popupRef  = useRef()
        useLayoutEffect(() => {                  
                const id = location.search.split('=')[1]
                requestProduct(id);
                getCountry()
        }, []);
        
        const getCountry = async () => {
                const response = fetch('http://ip-api.com/json/')
                const data = (await response).json()
                data.catch(err => {
                        console.warn(err)
                })
                setCountry(data.country)
        }

        
        const requestProduct = async (id) => {
                setLoading(true);
                let data = await Client.getEntry(id)
                setProduct(data);
                setLoading(false);
        }

        const addToCart = (product, e)=>{
                setCart([...cart, {product:product}]);
                popupRef.current.style.display="block";
        }
        
        // remove the popup alert from display
        const removePopup = (e)=>{
                e.target.parentElement.style.display="none";
        }
        
  return (
        <>
        {
                loading
                ?
                <LoadingPage />
                :
                <>
                        <Product product ={ product } country={ country } handleAddToCart={addToCart} />
                        <div ref={popupRef} className="popup">Product added... 
                                <Link to="/cart"><div>go to cart</div></Link>
                                <span className="closePopup" onClick={removePopup}><FaTimes /></span>
                        </div>
                </>
        }
        </>
    
  )
}

export default ProductDetails
