import React from 'react'
import { AiFillStar } from 'react-icons/ai';
import './Cart.css'

const CartItem = ({ product, handleRemove }) => {
    let medias = [""];
    
    const stars = Array(5).fill(0);
    const colors={
        active: "#F7D000",
        gray: "#a9a9a9"
    }

    const removeItem = (id)=>{
        handleRemove(id)
    }
    console.log(product);
    return (
            <div className="cartItemWrapper">
                <div className="cartFlexItem cartImageWrapper">
                    { product?.product?.fields?.images && <img src={product?.product?.fields?.images[0]?.fields?.file?.url} alt={product?.product?.fields?.name} /> }
                </div>
                <div className="cartFlexItem cartDescription">
                    <h3 className="cartItemName">{ product?.product?.fields?.name }</h3>
                    <div className="rating remove">
                        {stars.map((_, index)=>(
                            <AiFillStar key = {index} color={ Math.round(product?.product?.fields?.reviewCount) > index ? colors.active: colors.gray}/>
                        ))}
                        <div className="cartNumbers remove">{ product?.product?.fields?.sold } sold . All Levels</div>
                    </div>
                    <div className="cartMobile_price">${ product?.product?.fields?.price }</div>
                </div>
                <div className="cartFlexItem cartControl">
                    <div className="cartPrice">${ product?.product?.fields?.price }</div>
                    <div className="cartBtnControl">
                        <span onClick={() => {removeItem(product?.product?.sys?.id) }} className="cartBtn">remove</span>
                        <span className="cartBtn cartSaveToLater remove">save to later</span>
                    </div>
                </div>
            </div>
    )
}

export default CartItem
