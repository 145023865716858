import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/UserContext';

const Register = () => {
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [country, setCountry] = useState('');
        const [phone, setPhone] = useState('');
        const [user, setUser] = useContext(UserContext);
        
        
        
        const sendUser = (e) => {
                e.preventDefault()
                const userData = {
                        "email": email,
                        "country": country,
                        "phone": phone
                }
                setUser(userData)
                window.location.reload()
        }
  return (
        <>
        <form className='authForm' onSubmit={sendUser}>
                <div className='inputContainer'>
                        <input  value={country} onChange={(e) => setCountry(e.target.value)} type='text' placeholder='Input Country' />
                </div>
                <div className='inputContainer'>
                        <input  value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email Address' />
                </div>
                <div className='inputContainer'>
                        <input  value={phone} onChange={(e) => setPhone(e.target.value)} type='text' placeholder='Phone Number' />
                </div>
                <div className='inputContainer'>
                        <input  value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Password' />
                </div>
                <div className='loginBtn'>
                        <input type='submit' value="Create Acount" />
                </div>
        </form>

        </>
  )
}

export default Register
