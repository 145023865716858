import React from 'react'
import './Home.css'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import SuperDeals from '../../Assets/img/super_deals.webp'
import Watch from '../../Assets/img/nav/bags/H64df0c4d207748f1b6834b6f9e255cde8.jpg_300x300Q70.jpg_.webp'
import { Link } from 'react-router-dom'
import ImageDiscount from './ImageDiscount'
import { FiArrowRight } from 'react-icons/fi'
import { BsStarFill } from 'react-icons/bs'
import Off from '../Addons/Off'
import useLocalStorage from '../../UseLocalStorage'
import ProductPage from '../Products/ProductPage'

const Home = ({products}) => {
        
        const productsCopy = [...products]
        let discountedProduct = []

        productsCopy.forEach(item => {
                if (discountedProduct.length <= 6){
                        if(item.fields.discount > 0){
                                discountedProduct.push(item)
                        }
                }
        })

        let recommendation = discountedProduct.splice(0, 3)
        let limited = discountedProduct.splice(0, 3)
  return (
    <>
        <div className='deals'>
                <div className='super_deals'>
                        <img src={SuperDeals} alt='super deals'/>
                </div>
                <div className='span_device'>
                        <div className='categories'>
                                <div className='category'>
                                        <div className='desc'>Recommended for you</div>
                                        {
                                                recommendation.map( product => (
                                                <div className='discountWrapper' key={ product?.sys?.id }>
                                                        <Link className='discountProduct' to= {`/products?id=${product?.sys?.id}`}>
                                                                { product?.fields?.images && <ImageDiscount image={product?.fields?.images[0]?.fields?.file?.url} /> }
                                                                <div>
                                                                        <span className='discount_product_name'>
                                                                                {(product?.fields?.name).substring(0, 25)}{(product?.fields?.name).length > 25 ? '...': ''}
                                                                        </span>
                                                                        <p>
                                                                                <span className='price'>USD {product?.fields?.price- (product?.fields?.price *product?.fields?.discount /100)}</span>
                                                                                { product?.fields?.discount > 0 && <span className='price priceOld'>USD { product?.fields?.price}</span>}
                                                                        </p>
                                                                        { product?.fields?.discount > 0 && <Off discount={product?.fields?.discount} />}
                                                                </div>
                                                        </Link>
                                                </div>

                                                ))
                                        }
                                </div>
                                <div className='category'>
                                        <div className='desc'>limited time low prices</div>
                                        {
                                                limited.map( product => (
                                                <div className='discountWrapper' key={ product?.sys?.id }>
                                                        <Link className='discountProduct' to= {`/products?id=${product?.sys?.id}`}>
                                                                { product?.fields?.images && <ImageDiscount image={product?.fields?.images[0]?.fields?.file?.url} /> }
                                                                <div>
                                                                        <span className='discount_product_name'>
                                                                                {(product?.fields?.name).substring(0, 25)}{(product?.fields?.name).length > 25 ? '...': ''}
                                                                        </span>
                                                                        <p>
                                                                                <span className='price'>USD {product?.fields?.price- (product?.fields?.price *product?.fields?.discount /100)}</span>
                                                                                { product?.fields?.discount > 0 && <span className='price priceOld'>USD { product?.fields?.price}</span>}
                                                                        </p>
                                                                        { product?.fields?.discount > 0 && <span className='price-discount'>{product?.fields?.discount}% off</span> }
                                                                </div>
                                                        </Link>
                                                </div>

                                                ))
                                        }
                                </div>
                                <div className='category category_view_more'>
                                        <Link to='/products' className='desc'>view more <span className='icon'> <FiArrowRight /> </span></Link>
                                </div>
                        </div>

                </div>
        </div>

        <div className='more_to_love'>
                <div className='head '>More to love</div>
                <ProductPage products={products} />
        </div>
    </>
  )
}

export default Home
