import React, { useContext, useState } from 'react'
import { UserContext } from '../../Context/UserContext';

const Signin = () => {
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [user, setUser] = useContext(UserContext);
        

        const sendUser = () => {
                const userData = {
                        "email": email
                }
                setUser(userData)
                //send email to user
        }
  return (
         <>
        <form className='authForm' onSubmit={sendUser}>
                <div className='inputContainer'>
                        <input  value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email Address' />
                </div>
                <div className='inputContainer'>
                        <input  value={password} onChange={(e) => setPassword(e.target.value)} type='password' placeholder='Password' />
                </div>
                <div className='loginBtn'>
                        <input type='submit' value="Create Acount" />
                </div>
        </form>

        </>
  )
}

export default Signin
