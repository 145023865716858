import React, { useState } from 'react'
import './Contact.css'

const Contact = () => {
        const [email, setEmail] = useState('');
        const [message, setMessage] = useState('');
        const [name, setName] = useState('');
        const [subject, setSubject] = useState('');
        
        

        const sendUser = () => {

        }
        
  return (
    <div className='contactus_page'>
      <div className='contact_header'>
                <h3>Contact us</h3>
                <p>Send us a message</p>
      </div>
      <div className='contact_form'>
                <form className='authForm' onSubmit={sendUser}>
                        <div className='inputContainer'>
                                <input  value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Full Name' />
                        </div>
                        <div className='inputContainer'>
                                <input  value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email Address' />
                        </div>
                        <div className='inputContainer'>
                                <input  value={subject} onChange={(e) => setSubject(e.target.value)} type='text' placeholder='Subject' />
                        </div>
                        <div className='inputContainer messageContainer'>
                                <textarea  value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Enter Message' />
                        </div>
                        <div className='loginBtn'>
                                <input type='submit' value="Send message" />
                        </div>
                </form>
      </div>
    </div>
  )
}

export default Contact
