import React from 'react'
import { useInView } from 'react-intersection-observer';


import './Landing.css'
import { Link, useNavigate } from 'react-router-dom'
import MechanicOne from "../../Assets/img/kenny-eliason-2K_-PG95qlI-unsplash.jpg";
import MechanicTwo from "../../Assets/img/todd-kent-onnJOfF-okU-unsplash.jpg";
import BMW from "../../Assets/img/Home/bmw-2-series-coupe-2022-oem-02-angle-dynamic-exterior-front-white.webp";
import Honda from "../../Assets/img/Home/honda-accord-hybrid-sport-2023-01-exterior-front-angle-scaled.webp";
import Lexus from "../../Assets/img/Home/lexus-rx-350-awd-2023-01-exterior-front-angle-scaled.webp";
import Mercedes from "../../Assets/img/Home/mercedes-benz-eqs-580-suv-2023-01-exterior-front-angle-scaled.webp";
import Toyota from "../../Assets/img/Home/toyota-highlander-hybrid-platinum-2023-02-exterior-front-angle-scaled.webp";
import Nissan from "../../Assets/img/Home/nissan-altima-sr-2023-01-exterior-front-angle-scaled.webp";
import Acura from "../../Assets/img/Home/acura-integra-2023-01-exterior-front-angle-scaled.webp";
import Audi from "../../Assets/img/Home/audi-q4-e-tron-2022-02-exterior-front-angle-scaled.webp";
import Ford from "../../Assets/img/Home/ford-f-150-raptor-r-2023-06-exterior-front-angle-scaled.webp";
import Volkswagon from "../../Assets/img/Home/volkswagon.jpg";
import volvo from "../../Assets/img/Home/volvo-v90-b6-awd-2022-01-black-exterior-profile-wagon-scaled.webp";

const carMakes = [
        {
                "make": "BMW",
                "image": BMW
        },
        {
                "make": "Honda",
                "image": Honda
        },
        {
                "make": "Lexus",
                "image": Lexus
        },
        {
                "make": "Mercedes",
                "image": Mercedes
        },
        {
                "make": "Toyota",
                "image": Toyota
        },
        {
                "make": "Nissan",
                "image": Nissan
        },
        {
                "make": "Acura",
                "image": Acura
        },
        {
                "make": "Audi",
                "image": Audi
        },
        {
                "make": "Ford",
                "image": Ford
        },
        {
                "make": "Volkswagon",
                "image": Volkswagon
        },
        {
                "make": "Volvo",
                "image": volvo
        },
]

const LandingPage = () => {
        const navigate = useNavigate()

        const filterCar = (make) => {
                navigate('/product-list?id='+make.toLowerCase())
        }
        const { ref, inView, entry } = useInView({
                /* Optional options */
                threshold: 0,
        });

  return (
    <div className='home_page'>
        <div className='heroSection_home'>
                <div className='hero_content_wrapper_home'>
                        <h2>Your Solution Path to Becoming a Successful Mechanic</h2>
                        <p>Repare cars and other get all accessories for your car in one place</p>
                        <div>
                                <Link className='callToAction' to='/en'>Start Shopping</Link>
                        </div>
                </div>
        </div>
        <div className='container'>
                <section className='home_flex animateOne'>
                        <div className='home_flex_text'>
                                <div className='title'>Tired of buying the wrong parts for your vehicle. Are you lossed yet?</div>
                                <div className='description'>There is no shortage of ecommerce websites and blogs on car mechanics can use to purchase car parts online today. In fact, we have the opposite problem. We have too many of them. How often do you watch a buy stuff online and end up not getting what you wanted? Or realize you ordered the wrong product, that is what they show you is not what you get. Come buy here. We show you exactly what you get. No photoshop </div>
                        </div>
                        <div className='homeImgWrapper'>
                                <img className='mechanic' src={MechanicOne} alt='mechanic' />   
                        </div>
                </section>
                <section ref={ref} className={`home_flex second_section ${inView ? 'animateOne': ''}`}>
                        <div className='homeImgWrapper'>
                                <img className='mechanic' src={MechanicTwo} alt='mechanic' />   
                        </div>
                        <div ref={ref} className={`home_flex_text ${inView ? 'animateOne': ''}`}>
                                <div className='title'>Where to get quality spare parts </div>
                                <div className='description'>Looking for high-quality used auto spare parts? We have the perfect selection of second-hand parts from reliable retailers that won’t break the bank! Whether you’re looking for a small replacement part or a large industrial machine part, used spare parts can be a great way to get reliable parts without breaking the bank. With our selection of high-quality second-hand parts from trusted retailers, you can find the perfect part for your project.</div>
                        </div>
                </section>
                <section className='productList'>
                        <div className='title'>Car Makes you'll find here</div>
                        <div className='homeSectionComponent'>
                                <div className='sectionArray'>
                                        {
                                                carMakes.map(carmake => (
                                                        <CarMake make={carmake} handleFilterCar={filterCar} />
                                                ))
                                        }
                                </div>
                        </div>
                </section>

        </div>
    </div>
  )
}

export default LandingPage



const CarMake = ({ make, handleFilterCar }) => {
        const { ref, inView, entry } = useInView({
                /* Optional options */
                threshold: 0,
        });
  return (
    <div ref={ref} className={` carHide ${inView ? 'animateOne': ''}`} onClick={() => {handleFilterCar(make.make)}}  id='item' to={`/products/item`}> 
                <div className='sectionItem'>
                        <div className='imgWrapper'>
                                <img className="productImg skeleton" src={ make.image } alt='bmw' />
                        </div>
                        <div className='desc'>
                                <p>{ make.make }</p>
                        </div>
                </div>
        </div>
  )
}
