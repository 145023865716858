import React, { useState } from 'react'
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai'
import { Link } from 'react-router-dom'

const FooterMobile = () => {
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [subject, setSubject] = useState('');
        const [message, setMessage] = useState('');
        
        const sendForm = (e) => {
                e.preventDefault()


                window.location.reload()

        }
  return (
    <div className='footer_mobile'>
        <div className='container'>
                <div className='footerContent footer_m_grid'>
                        <div className='branches'>
                                <div className="descTitle">Branches</div>
                                <p className='descText'>162, ALKIVIADOU STREET</p>
                                <p className='descText'>18535, PIRAEUS – GREECE</p>
                                <a href="tell:+13395261104"><div className="descText"><AiOutlinePhone /> Tell</div></a>
                                <a href="mailto:stonershouseverified@gmail.com"><div className="descText">
                                        <AiOutlineMail /> email us</div></a>
                        </div>
                        <div className="buy">
                                <div className="descTitle">our services</div>
                                <Link to="/products"><div className="descText">store</div></Link>
                                <Link to="/"><div className="descText"> website accessibility policy </div></Link>
                                <Link to="/contact-us"><div className="descText">stories </div></Link>
                                <Link to="/about"><div className="descText"> about</div></Link>
                                <Link to="/contact-us"><div className="descText">help </div></Link>
                        </div>
                        <div className="others">
                                <div className="descTitle">Others</div>
                                <Link to="/contact-us"><div className="descText">FAQs </div></Link>
                                <Link to="/contact-us"><div className="descText">report an error </div></Link>
                                <Link to="/contact-us"><div className="descText">code of ethics </div></Link>
                        </div>
                        <div className="formFooter">
                                <form onSubmit={sendForm}>
                                        <div className='footer_form_input'>
                                                <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Full Name' />
                                        </div>
                                        <div className='footer_form_input'>
                                                <input value={email} onChange={(e) => setEmail(e.target.value)} type='text' placeholder='Email' />
                                        </div>
                                        <div className='footer_form_input'>
                                                <input value={subject} onChange={(e) => setSubject(e.target.value)} type='text' placeholder='Subject' />
                                        </div>
                                        <div className='footer_form_input message_field'>
                                                <textarea value={message} onChange={(e) => setMessage(e.target.value)} type='text' placeholder='Message' />
                                        </div>
                                        <div className='loginBtn'>
                                                <input type='submit' value="Send message" />
                                        </div>
                                </form>
                        </div>
                </div>
        </div>
    </div>
  )
}

export default FooterMobile
