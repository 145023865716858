import React, { useContext, useState } from 'react'
import './Nav.css'
import './NavDesktop.css'
import { RxHamburgerMenu } from 'react-icons/rx'
import { RiUser6Line, RiSearch2Line, RiContactsLine } from 'react-icons/ri'
import { FiHelpCircle, FiShoppingCart } from 'react-icons/fi'

import { TbHome } from 'react-icons/tb'
import { HiChevronRight } from 'react-icons/hi'
import { IoPersonOutline } from 'react-icons/io5'
import Flag from 'react-flagkit';


import { IoIosLogOut, IoMdHeartEmpty } from 'react-icons/io'
import { BsPhone } from 'react-icons/bs'
import { BiSupport } from "react-icons/bi";

import { AiFillCaretDown } from 'react-icons/ai'
import { Link, useNavigate } from 'react-router-dom'
import LinkImage from './LinkImage'


import Engine from '../../Assets/nav/Engine.webp'
import Fuel from '../../Assets/nav/75_cp2_01.png'
import Cooling from '../../Assets/nav/75_cp2_02.png'
import Transmission from '../../Assets/nav/75_cp2_09.png'
import CoolingHeat from '../../Assets/nav/75_cp2_04.png'
import Spring from '../../Assets/nav/75_cp2_08.png'
import Electric from '../../Assets/nav/75_cp2_05.png'
import uncategorized from '../../Assets/nav/75_cp2_07.png'

import { CartContext } from '../../Context/CartContext'
import { UserContext } from '../../Context/UserContext'
const Nav = ({ handleShowLoginModal }) => {
        const [searchString, setSearchString] = useState('');
        const [showNav, setShowNav] = useState(false);
        const [cart, setCart] = useContext(CartContext);
        const [user, setUser] = useContext(UserContext);
        
        const navigate = useNavigate()
        const removeNav = (e)=>{
                if(e.target.className === 'navLink'){
                        setShowNav(false)
                }
        }

        const sellOnLLCParts = () => {
                
        }
        const logout = () => {
                setUser(null)
        }

        const Route = (e) => {
                console.log(e);
                navigate(e)
        }
        const navLinks = [
                {
                        name: "Engine",
                        url: "engine",
                        img: Engine
                },
                {
                        name: "fuel systems & lubricant",
                        url: "fuel",
                        img: Fuel
                },
                {
                        name: "Body & Exaust",
                        url: "body",
                        img: Cooling
                },
                {
                        name: "Transmission",
                        url: "transimission",
                        img: Transmission
                },
                {
                        name: "cooling & heating",
                        url: "cooling",
                        img: CoolingHeat
                },
                {
                        name: "wheel & spring",
                        url: "wheel",
                        img: Spring
                },
                {
                        name: "light & electrics",
                        url: "light",
                        img: Electric
                },
                {
                        name: "uncategorized",
                        url: "uncategorized",
                        img: uncategorized
                }
        ]

  return (
        <div className='navWrapper'>
                <div className='container'>

                        <div className='navBar navMobileFix'>
                        <div className='navMobile'>
                                <div>
                                        <div className='mobile-top'>
                                                <div className='logo-burger center'>
                                                        <span onClick={() => setShowNav(true)} className='burger nav-icon'>
                                                                <RxHamburgerMenu />
                                                        </span>
                                                        <a href="/en">
                                                                <span className="logo-base">LLC Parts</span>
                                                        </a>
                                                </div>
                                                <div>
                                                        <span onClick={() => handleShowLoginModal(true)} className="nav-icon" >
                                                                <RiUser6Line />
                                                        </span>
                                                        <Link to='/cart' className="nav-icon" >
                                                                <FiShoppingCart />
                                                        </Link>

                                                </div>
                                        </div>
                                        <div className='search'>
                                                <input type='text' onChange={e => setSearchString(e.target.value)} value={searchString} placeholder='search...' />
                                                <span className='searchIcon'>
                                                        <RiSearch2Line />
                                                </span>
                                        </div>
                                </div>
                                <div className={`nav-mobile ${showNav ? 'showNav': ''}`}>
                                        <div className={`shadow ${showNav && 'showShadow'}`} onClick={() => setShowNav(false)} />
                                        <div className='nav-content' >
                                                <div className='logo-burger nav-inside-logo'>
                                                        <span className='burger nav-icon'>
                                                                <TbHome />
                                                        </span>
                                                        <a href="/en">
                                                                <span className="logo-base">LLC Parts</span>
                                                        </a>
                                                </div>
                                                <div onClick={ removeNav } className='nav-list'>
                                                        <div className='nav-head'>
                                                                <div className='title'>popular category</div>
                                                                <div className='icon'><HiChevronRight /></div>
                                                        </div>
                                                        <ul className='navLinks'>
                                                                {
                                                                        navLinks.map((navItem, index) => (
                                                                        <li key={index}>
                                                                                <Link className='navLink' to= {`/product-list?id=${navItem.url}`}>
                                                                                        <LinkImage image={navItem.img} />
                                                                                        <span>{navItem.name}</span>
                                                                                </Link>
                                                                        </li>

                                                                        ))
                                                                }
                                                        </ul>
                                                        <div className='nav-head'>
                                                                <div className='title'>Shopping inspirations</div>
                                                                <div className='icon'><HiChevronRight /></div>
                                                        </div>
                                                        <ul className='navLinks'>
                                                                <li>
                                                                        <Link className='navLink' to= '/products/clothes/women'>
                                                                                <LinkImage image="" />
                                                                                <span>interface kit for ipod</span>
                                                                        </Link>
                                                                </li>
                                                                <li>
                                                                        <Link className='navLink' to= '/products/watches'>
                                                                                <LinkImage image="" />
                                                                                <span>leather floor mat</span>
                                                                        </Link>
                                                                </li>
                                                                <li>
                                                                        <Link className='navLink' to= '/products/watches'>
                                                                                <LinkImage image="" />
                                                                                <span>truck mats</span>
                                                                        </Link>
                                                                </li>
                                                        </ul>
                                                        <div className='nav-head'>
                                                                <div className='title'>Settings</div>
                                                                <div className='icon'><HiChevronRight /></div>
                                                        </div>
                                                        <ul className='navLinks'>
                                                                <li>
                                                                        <Link className='navLink mobileNavLink_bot' to= '/products/clothes/women'>
                                                                                <span className='navLinkIcon_mobile'><Flag country="US" /></span>
                                                                                <span>Ship to</span>
                                                                        </Link>
                                                                </li>
                                                                <li>
                                                                        <Link className='navLink mobileNavLink_bot' to= '/frequently-asked-question'>
                                                                                {/* <LinkImage image="" /> */}
                                                                                <span className='navLinkIcon_mobile'>
                                                                                        <FiHelpCircle />
                                                                                </span>
                                                                                <span>FAQ</span>
                                                                        </Link>
                                                                </li>
                                                                <li>
                                                                        <Link className='navLink mobileNavLink_bot' to= '/contact-us'>
                                                                                <span className='navLinkIcon_mobile'>
                                                                                        <RiContactsLine />
                                                                                </span>
                                                                                <span>Contact Us</span>
                                                                        </Link>
                                                                </li>
                                                                <li>
                                                                        <Link className='navLink mobileNavLink_bot' to= '/frequently-asked-question'>
                                                                                <span className='navLinkIcon_mobile'>
                                                                                        <BiSupport />
                                                                                </span>
                                                                                <span>Help center</span>
                                                                        </Link>
                                                                </li>
                                                        </ul>
                                                </div>
                                        </div>
                                </div>
                        </div>

                        {/* ================Nav desktop================= */}
                        <nav>
                                <div className='top'>
                                        <ul className='topLinks'>
                                                <li>
                                                        <div className='linkTopWrapper dropdown_anchore'>
                                                                <span className='linkTop' onClick={sellOnLLCParts}>help</span>
                                                                <span className='iconB'>
                                                                        <AiFillCaretDown />
                                                                </span>
                                                                <div className='dropdownNav'>
                                                                        <div className='dropdownNav-item'>Help Center</div>
                                                                        <div className='dropdownNav-item'><Link to='/frequently-asked-question'>FAQs</Link></div>
                                                                        <div className='dropdownNav-item'>Trust and Safety</div>
                                                                        <div className='dropdownNav-item'><Link to='/contact-us'>Contact Us</Link></div>
                                                                </div>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper'>
                                                                <span className='linkTop' onClick={sellOnLLCParts}>buyer protection</span>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper'>
                                                                <span className='iconB iconApp'>
                                                                        <BsPhone />
                                                                </span>
                                                                <span className='linkTop' onClick={sellOnLLCParts}>app</span>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper dropdown_anchore'>
                                                                <span className='linkTop' onClick={sellOnLLCParts}>English</span>
                                                                <span className='iconB'>
                                                                        <AiFillCaretDown />
                                                                </span>
                                                                <div className='dropdownNav'>
                                                                        <div className='bold subtitle'>languages</div>
                                                                        <div className='subDropDown language_dropdown'>
                                                                                {/* {
                                                                                        languages.map((language, index) => (
                                                                                        <p key={index} className="dropdownNav-item">
                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className={`flag-icon flag-icon-${language.flag}`}></span>
                                                                                                <span  onClick={(e) => handleChangeLanguage(language.code, e)} className='blacktext'>{language.name}</span>
                                                                                        </p>
                                                                                        ))
                                                                                } */}
                                                                        </div>

                                                                        <div className='bold subtitle'>Currency</div>
                                                                        <div className='subDropDown nav_selection_list'>
                                                                                {/* <CurrencySelect /> */}
                                                                        </div>
                                                                </div>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper'>
                                                                <span className='iconB iconApp'>
                                                                        <IoMdHeartEmpty />
                                                                </span>
                                                                <span className='linkTop' onClick={sellOnLLCParts}>wish list</span>
                                                        </div>
                                                </li>
                                                <li>
                                                        <div className='linkTopWrapper dropdown_anchore'>
                                                                <span className='iconB iconApp'>
                                                                        <IoPersonOutline />
                                                                </span>
                                                                <span className='linkTop' onClick={sellOnLLCParts}>account</span>
                                                                <div className='dropdownNav'>
                                                                        <div className='dropdownNav-item'>Profile - {user?.email}</div>
                                                                        <div className='dropdownNav-item'>Switch account</div>
                                                                        <div className='dropdownNav-item'>Settings</div>
                                                                        {
                                                                                !user?.email
                                                                                ? (
                                                                                <div onClick={() => handleShowLoginModal(true)} className='dropdownNav-item'>login/register</div>
                                                                                ):(
                                                                                        
                                                                                <div onClick={logout}  className='dropdownNav-item logoutNavLink'>
                                                                                        <span className='accountIcon'>
                                                                                                <IoIosLogOut />
                                                                                        </span>
                                                                                        logout
                                                                                </div>
                                                                                )

                                                                        }
                                                                </div>
                                                        </div>
                                                </li>
                                        </ul>
                                </div>
                                <div className='nav-main'>
                                        <div className='right'>
                                                <a href="/en">
                                                        <span className="logo-base">LLC Parts</span>
                                                </a>
                                        </div>
                                        <div className='searchWrapper'>
                                                <div className='search'>
                                                        <input type='text' onChange={e => setSearchString(e.target.value)} value={searchString} placeholder='search...' />
                                                        <span className='searchIcon'>
                                                                <RiSearch2Line />
                                                        </span>
                                                </div>
                                        </div>
                                        <Link to='/cart' className='cartBtn'>
                                                <span className='searchIcon'>
                                                        <FiShoppingCart />
                                                </span>
                                                <span className='cart-count'>{ cart.length }</span>
                                        </Link>
                                </div>
                                <div className='desktop_banner'>
                                        <div className='banner_nav_wrappper'>
                                                <ul>
                                                        <li className='navLink' onClick={() => { Route('/product-list?id=all')}}>
                                                                <Link to='/products' className='banner_navLink'>spare parts</Link>
                                                        </li>
                                                        <li className='navLink' onClick={() => { Route('/contact-us')}}>
                                                                <Link to='/contact' className='banner_navLink'>contact</Link>
                                                        </li>
                                                        <li className='navLink' onClick={() => { Route('/about')}}>
                                                                <Link to='/about' className='banner_navLink'>About</Link>
                                                        </li>
                                                </ul>
                                        </div>
                                </div>
                        </nav>
                        </div>
                </div>
                
        </div>
  )
}

export default Nav
