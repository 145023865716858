import React, { useState } from 'react'
import './Faq.css'
import { RiSearch2Line } from 'react-icons/ri'
import FaqQuestion from './FaqQuestion'
import { Link } from 'react-router-dom'
const Faq = () => {
        const [showSupportModal, setShowSupportModal] = useState(false);
        

  return (
    <div className='frequently_asked_question'>
        <div className='heroSection'>
                <div className='container'>
                        <h1 className='hero_title'>Welcome to our Q&A section</h1>
                        <p className='hero_title'>How can we help you</p>
                        <div className='search_faq_wrapper_n'>
                                <div className='search_faq_n'>
                                        <input type='text' className='faq_search_input' placeholder='search question...' />
                                        <div className='searchIcon'>
                                                <RiSearch2Line />
                                        </div>
                                </div>
                        </div>
                </div>
        </div>
        <div className='container'>
                <div className='frequent_asked_question_list'>
                        <FaqQuestion question="How to unblock my account" answer="your account has been permanently banded, you can no longer access this site" />
                        <FaqQuestion question="How to create an account" answer="click on the login button and enter your login credentials to continue. " />
                        <div className='faq_need_help_btn'>
                                <p>Need more help?</p>
                                <Link to="/contact-us" className='faq_btn_n'>Contact Support</Link>
                        </div>
                </div>
        </div>
    </div>
  )     
}

export default Faq
