import React from 'react'
import { BsStarFill } from 'react-icons/bs'
import { Link } from 'react-router-dom'

const ProductPage = ({ products }) => {
  return (
        <>
        {
                products.length < 1
                ? 
                <h3>No products to show</h3>
                :
                <div className='home_grid'>
                        {
                                products?.map(product => (
                                        <Link to={`/products?id=${product?.sys?.id}`} key={ product?.sys?.id } className='home_grid_item'>
                                                <div className='imgWrapper'>
                                                        <img className='productList_image' src={product?.fields?.images[0]?.fields?.file?.url} alt={product?.fields?.name} />
                                                </div>
                                                <div className='desc'>
                                                        <p>
                                                                <span className='price'>USD {product?.fields?.price- (product?.fields?.price *product?.fields?.discount /100)}</span>
                                                                { product?.fields?.discount > 0 && <span className='price priceOld'>USD {product?.fields?.price}</span>}
                                                        </p>
                                                        <p className='sold-rating'>
                                                                <span className='sold'>{product?.fields?.sold}+ sold</span>
                                                                <span className='rating'>{ product?.fields?.reviewCount } <span className='icon'><BsStarFill /></span></span>
                                                        </p>
                                                        {/* <span className='top_selling_tag'>top selling</span> */}
                                                        <p className='discount_product_name'>{product?.fields?.name}</p>
                                                </div>
                                        </Link>
                                ))
                        }
                </div>
        }
        </>
  )
}

export default ProductPage
