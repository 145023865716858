import React, { useState,useEffect } from 'react'
import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Nav from './Components/Nav/Nav';
import LoadingPage from './LoadingPage'
import Home from './Components/Home/Home';
import Footer from './Components/Footer/Footer';
import ProductDetails from './Components/Products/ProductDetails';
import Discount from './Discount'
import Cart from './Components/Cart/Cart';
import LoginModal from './Components/Auth/LoginModal';
import useLocalStorage from './UseLocalStorage';
import { Client } from './Client';
import Contact from './Components/Contact/Contact';
import Faq from './Components/FAQ/Faq';
import ProductList from './Components/Products/ProductList';
import LandingPage from './Components/Landing/LandingPage';
import NavF from './Components/Landing/NavF';
import { FaWhatsapp } from 'react-icons/fa';
import FooterMobile from './Components/Landing/FooterMobile';


const LazyImport = props => {
  const { LazyComponent, ...restOfProps } = props;
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <LazyComponent {...restOfProps} />
    </React.Suspense>
  );
};

function App() {

        const [loginModal, setLoginModal] = useState(false);
        const [products, setProducts] = useLocalStorage('products', [])
        const [country, setCountry] = useState('USA');
        const location = useLocation().pathname
        
        useEffect(() => {  
          Client.getEntries()
          .then(res=>{
            const products = res?.items.filter((product) => product.fields.price !== undefined )
            setProducts(products);   
          })
          .catch(err=>console.log(err))
        }, []); 


        const modalControl = (state) => {
          setLoginModal(state)
        }
        
  return (
    <div className="App">
      {
        location === '/'
        ?
        <NavF />
        :
        <>
          <Discount />
          <Nav handleShowLoginModal={modalControl} />
        </>
      }
      <Routes>
        <Route path='/' element={<LandingPage />} />
      </Routes>
      <a className='whatsapp' target='_blank' rel='noopener noreferrer' href='https://wa.me/+13395261104'>
        <FaWhatsapp />
      </a>
      <div className='container'>
        <Routes>
          <Route path='/en' element={<Home products={products} />} />
          <Route path='/products/?' element={<ProductDetails products={products} />} />
          <Route path='/product-list/?' element={<ProductList /> } />
          <Route path='/cart' element={<Cart products={products} /> } />
          <Route path='/contact-us' element={<Contact /> } />
          <Route path='/frequently-asked-question' element={<Faq />} />
        </Routes>
      </div>
      {
        loginModal && (
          <LoginModal handleShowLoginModal={modalControl} />
        )
      }
      <Footer />
      <FooterMobile />
    </div>
  );
}

export default App;
