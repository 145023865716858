import React, { useState } from 'react'
import { RxHamburgerMenu } from 'react-icons/rx'
import { Link } from 'react-router-dom'

const NavF = () => {
        const [showNav, setShowNav] = useState(false);
        
  return (
    <div className='nav_home'>
        <div className='container flexHead'>
                <div className='nav_home_mobile_head'>
                        <a href="/en">
                                <span className="logo-base logo-white">LLC Parts</span>
                        </a>
                        <div onClick={() => {setShowNav(!showNav)}} className='burger'>
                                <RxHamburgerMenu />
                        </div>
                </div>
                <div className={`nav_home_content ${ showNav ? 'showNavHome': '' }`}>
                        <ul className='nav_home_navLinks'>
                                <li>
                                        <Link to='/about'>About Us</Link>
                                </li>
                                <li>
                                        <Link to='/product-list?id=all'>Spare Parts</Link>
                                </li>
                                <li>
                                        <Link to='/frequently-asked-question'>FAQ</Link>
                                </li>
                                <li>
                                        <Link to='/contact-us'>Contact Us</Link>
                                </li>
                                <li>
                                        <Link to='/contact-us'>Join Us</Link>
                                </li>
                        </ul>
                </div>
                
        </div>
    </div>
  )
}

export default NavF
